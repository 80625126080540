import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./components/auth/routes').then((mod) => mod.AUTH_ROUTES),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./components/admin/routes').then((mod) => mod.ADMIN_ROUTES),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./components/reports/routes').then((mod) => mod.REPORT_ROUTES),
  },
];
