import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, filter, interval, map, switchMap, tap } from 'rxjs';
import { AdminActions } from './../../../../state/admin/actions';

import {
  CameraVariableType,
  ICameraVariable,
  IDevice,
  pointVariables as allPointVariables,
} from '../../../../model/dashboard';
import { adminFeature } from '../../../../state/admin/feature';
import { AuthActions } from '../../../../state/auth/actions';
import { DRYAIR_MSG_ID } from '../../../../utils/device';
import { DashboardActions } from '../../dashboard/state/dashboard.actions';
import { NodesService } from '../services/nodes.service';
import {
  DataApi,
  IGetCurrentCamPoint,
  IVariableToDisplay,
  NodesOrderPostRequest,
  SetLocationNodeData,
  UserApi,
} from './../../../../api/api-sdk';
import { NodesActions } from './nodes.actions';
import { nodesFeature } from './nodes.feature';

@Injectable()
export class NodesEffects {
  private readonly nodesSortFieldKey = 'sigrow:nodesSortField';

  loadNodesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AdminActions.activeLocationConfigRetrived,
        NodesActions.updateNodesData,
      ),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      filter((values) => values.every((v) => !!v)),
      switchMap(([, activeLocation]) =>
        this.dataApi
          .nodesDataRetrieve(activeLocation!.location!.central_id)
          .pipe(
            map((res) =>
              NodesActions.nodesDataUpdated({
                nodesData: res.nodes_data,
              }),
            ),
          ),
      ),
    );
  });

  loadVisualMaps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationConfigRetrived),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      filter((values) => values.every((v) => !!v)),
      switchMap(([, activeLocation]) =>
        this.dataApi
          .visualMapRetrieve(activeLocation!.location!.central_id)
          .pipe(
            map((res) =>
              NodesActions.visualMapsFetched({
                visualMaps: res.visual_maps,
              }),
            ),
          ),
      ),
    );
  });

  loadNodesOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationConfigRetrived),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      filter((values) => values.every((v) => !!v)),
      switchMap(([, activeLocation]) =>
        this.dataApi
          .nodesOrderRetrieve(activeLocation!.location!.central_id)
          .pipe(
            map((res) =>
              NodesActions.customNodesOrderFetched({
                nodesOrder: res.nodes_order ?? [],
              }),
            ),
          ),
      ),
    );
  });

  nodesDataDisplay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        NodesActions.nodeUpdated,
        NodesActions.nodesDataUpdated,
        NodesActions.toggleAttribute,
        AdminActions.variableConfigUpdated,
      ),
      concatLatestFrom(() => [
        this.store.select(nodesFeature.selectNodesState),
        this.store.select(adminFeature.selectAllDevices),
      ]),
      map(([, state, devices]) =>
        NodesActions.nodesAndAlertsUpdated({
          ...this.nodesMng.getNodesAndAlerts(state, devices),
        }),
      ),
    );
  });

  readingColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        NodesActions.nodeUpdated,
        NodesActions.nodesDataUpdated,
        NodesActions.toggleAttribute,
        AdminActions.variableConfigUpdated,
      ),
      concatLatestFrom(() => this.store.select(adminFeature.selectVariables)),
      switchMap(([, variables]) => this.nodesMng.getReadingColumns(variables)),
      map((columns) => NodesActions.readingColumnsUpdated({ columns })),
    );
  });

  nodeUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NodesActions.nodeUpdated),
        concatLatestFrom(() => [
          this.store.select(adminFeature.selectActiveLocation),
          this.store.select(nodesFeature.selectNodesData),
        ]),
        switchMap(([action, activeLocation, nodesData]) => {
          const node = nodesData.find((nd) => nd.remote_id === action.nodeId);
          if (!activeLocation || !node) {
            return EMPTY;
          }
          return this.userApi.locationNodeDataCreate(
            activeLocation.location!.central_id,
            new SetLocationNodeData({
              node_name: node.node_name,
              remote_id: node.remote_id,
              status: node.status,
              visible: node.visible,
              physical_location: node.physical_location,
              position: node.position,
              node_alerts: node.node_alerts,
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );

  openChartsForNode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NodesActions.openChartsForNode),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectDevicesForActiveLocation),
        this.store.select(adminFeature.selectActiveLocation),
        this.store.select(adminFeature.selectVariables),
        this.store.select(adminFeature.selectDevicePoints),
      ]),
      map(
        ([
          action,
          locationDevices,
          activeLocation,
          allVariables,
          devicePoints,
        ]) => {
          const devices: IDevice[] = [];
          const variables: IVariableToDisplay[] = [];
          const points: IGetCurrentCamPoint[] = [];
          const pointVariables: ICameraVariable[] = [];

          const device = locationDevices.find(
            (d) => d.remote_id === action.node.id,
          )!;

          devices.push(device);

          const remote = activeLocation?.location?.remotes.find(
            (r) => r.remote_id === device.remote_id,
          );

          const variableName =
            !!remote?.msg_id && [14, 24, 25].includes(remote.msg_id)
              ? 'weight_t'
              : 'temp';

          variables.push(allVariables.find((v) => v.name === variableName)!);

          if (remote?.msg_id === DRYAIR_MSG_ID && action.node.cameraId) {
            const camera = locationDevices.find(
              (d) => d.thermal_camera_id === action.node.cameraId,
            )!;
            if (camera) {
              devices.push(camera);
              const dryLeafPoint = devicePoints.find(
                (dp) =>
                  dp.thermal_cam_id === action.node.cameraId &&
                  dp.type === 'DRY_LEAF',
              );
              if (dryLeafPoint) {
                points.push(dryLeafPoint);
                pointVariables.push(
                  allPointVariables.find(
                    (v) => v.type === CameraVariableType.temperature,
                  )!,
                );
              }
            }
          }

          return DashboardActions.focusDevice({
            devices,
            variables,
            points,
            pointVariables,
          });
        },
      ),
    );
  });

  autoRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.navigateToApp),
      switchMap(() =>
        interval(60000).pipe(map(() => NodesActions.updateNodesData())),
      ),
    );
  });

  createVisualMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NodesActions.createVisualMap),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
      ]),
      switchMap(([action, activeLocation]) =>
        this.dataApi
          .visualMapCreate(
            activeLocation!.location!.central_id,
            undefined,
            action.name,
            undefined,
            undefined,
          )
          .pipe(
            map((res: any) =>
              NodesActions.visualMapUpdated({ visualMap: res.visual_map }),
            ),
          ),
      ),
    );
  });

  updateVisualMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NodesActions.updateVisualMap),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
      ]),
      switchMap(([action, activeLocation]) =>
        this.dataApi
          .visualMapCreate(
            activeLocation!.location!.central_id,
            action.id,
            action.name,
            action.data,
            action.image,
          )
          .pipe(
            map((res: any) =>
              NodesActions.visualMapUpdated({ visualMap: res.visual_map }),
            ),
          ),
      ),
    );
  });

  deleteVisualMapCallServiceAPI$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NodesActions.deleteVisualMap),
        concatLatestFrom(() => [
          this.store.select(adminFeature.selectActiveLocation),
        ]),
        switchMap(([action, activeLocation]) =>
          this.dataApi.visualMapDestroy(
            activeLocation!.location!.central_id,
            action.id,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  customNodesOrderUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NodesActions.customNodesOrderUpdated),
        concatLatestFrom(() =>
          this.store.select(adminFeature.selectActiveLocation),
        ),
        filter((values) => values.every((v) => !!v)),
        switchMap(([action, activeLocation]) =>
          this.dataApi.nodesOrderCreate(
            activeLocation!.location!.central_id,
            new NodesOrderPostRequest({ nodes_order: action.nodesOrder }),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  restoreNodesOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.navigateToApp),
      map(() => localStorage.getItem(this.nodesSortFieldKey)),
      filter((nodesSortField) => !!nodesSortField),
      map((nodesSortField) =>
        NodesActions.setNodesSortField({
          sortField: JSON.parse(nodesSortField ?? ''),
        }),
      ),
    );
  });

  persistSavedConfigs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NodesActions.setNodesSortField),
        concatLatestFrom(() => [
          this.store.select(nodesFeature.selectNodesSortField),
        ]),
        tap(([, nodesSortField]) =>
          localStorage.setItem(
            this.nodesSortFieldKey,
            JSON.stringify(nodesSortField),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dataApi: DataApi,
    private userApi: UserApi,
    private nodesMng: NodesService,
  ) {}
}
