<h2 mat-dialog-title>{{ "chartPointDetails" | translate }}</h2>
<div mat-dialog-content class="w-full lg:w-180 !pb-0">
  <table class="w-full border-collapse border border-slate-400 text-graydark">
    <thead>
      <tr>
        <th class="p-2 bg-slate-100 border border-slate-300">
          {{ "nodeName" | translate }}
        </th>
        <th class="p-2 bg-slate-100 border border-slate-300">
          {{ "timestamp" | translate }}
        </th>
        <th class="p-2 bg-slate-100 border border-slate-300">
          {{ "variable" | translate }}
        </th>
        <th class="p-2 bg-slate-100 border border-slate-300">
          {{ "reading" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      @for (tooltip of tooltips; track tooltip) {
        <tr>
          <td class="p-2 border border-slate-300">{{ tooltip.title }}</td>
          <td class="p-2 border border-slate-300">
            {{ tooltip.x | dpFromUnixMS | dpDateFormat: "DD/MM/YYYY hh:mm:ss" }}
          </td>
          <td class="p-2 border border-slate-300">
            {{ tooltip.variable | translate }}
          </td>
          <td class="p-2 border border-slate-300">
            {{ tooltip.y }} {{ tooltip.uom }}
          </td>
        </tr>
      }
    </tbody>
  </table>
  <div class="relative">
    <mat-form-field class="w-full mt-6">
      <mat-label>{{ "leaveComment" | translate }}</mat-label>
      <textarea matInput [formControl]="commentCtrl" rows="3"></textarea>
    </mat-form-field>
    <div class="absolute top-8 right-2 cursor-pointer border-none">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [style.background-color]="selectedColor | alpha: 0.5"
      >
        <mat-icon>palette</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu">
      @for (color of colors; track color) {
        <div mat-menu-item (click)="selectColor(color)" class="!justify-center">
          <div
            [style.background-color]="color"
            class="border-2 w-10 h-10 rounded-full"
          ></div>
        </div>
      }
    </mat-menu>
  </div>
</div>
<mat-dialog-actions class="bg-gray-50 !p-6 !pt-0 !justify-between">
  <button mat-flat-button [mat-dialog-close]="false">
    {{ "cancel" | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!commentCtrl.valid"
    (click)="confirm()"
  >
    {{ "confirm" | translate }}
  </button>
</mat-dialog-actions>
