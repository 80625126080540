import dayjs from 'dayjs';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dpFromUnixMS',
  standalone: true,
})
export class DpFromUnixMSPipe implements PipeTransform {
  transform(value: number | undefined) {
    return value ? dayjs.unix(value / 1000) : undefined;
  }
}
