import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import {
  delay,
  exhaustMap,
  filter,
  from,
  interval,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { ChartsCommentPostRequest, DataApi } from '../../../../api/api-sdk';
import {
  DashboardCacheType,
  IChartCommentConfig,
} from '../../../../model/dashboard';
import {
  DateRange,
  DateRangesPredefined,
  dateRanges,
} from '../../../../model/dateRange';
import { AdminActions } from '../../../../state/admin/actions';
import { adminFeature } from '../../../../state/admin/feature';
import { NodesActions } from '../../nodes/state/nodes.actions';
import { DashboardService } from '../services/dashboard.service';
import { AuthActions } from './../../../../state/auth/actions';
import { DashboardUtils } from './../../../../utils/dashboard';
import { DashboardActions } from './dashboard.actions';
import { dashboardFeature } from './dashboard.feature';

@Injectable()
export class DashboardEffects {
  private readonly savedConfigsPersistentKey = 'sigrow:savedConfigs';

  readings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AdminActions.activeLocationConfigRetrived,
        DashboardActions.dateRangeChanged,
      ),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
        this.store.select(dashboardFeature.selectDateRange),
      ]),
      filter((values) => values.every((v) => !!v)),
      switchMap(([, activeLocation, dateRange]) =>
        // TODO: change that to ensureReadingsCache
        this.dashboardMng.loadReadings(activeLocation!, dateRange).pipe(
          map((res) =>
            DashboardActions.cacheUpdated({
              key: DashboardCacheType.readings,
              value: res.datasets,
            }),
          ),
        ),
      ),
    );
  });

  points$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.togglePoint,
        DashboardActions.togglePointVariable,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectPoints),
        this.store.select(
          dashboardFeature.selectCacheByType(DashboardCacheType.points),
        ),
      ]),
      exhaustMap(([, dateRange, points, cache]) =>
        this.doEnsureCache(DashboardCacheType.points, () =>
          this.dashboardMng.ensurePointsCache(dateRange, points, cache),
        ),
      ),
    );
  });

  togglePointAfterFocus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.focusDevice),
      filter((action) => !!action.points.length),
      map((action) =>
        // TODO: Dispatch multiple actions here, one per action.points array element
        DashboardActions.togglePoint({ point: action.points[0] }),
      ),
    );
  });

  toggleImagesAfterFocus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.focusDevice),
      filter((action) => !!action.devices.some((d) => !!d.thermal_camera_id)),
      map(() => DashboardActions.toggleImagesDisplay()),
    );
  });

  customPoints$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleCustomPoint,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectCustomPoints),
        this.store.select(
          dashboardFeature.selectCacheByType(DashboardCacheType.customPoints),
        ),
      ]),
      exhaustMap(([, dateRange, customPoints, cache]) =>
        this.doEnsureCache(DashboardCacheType.customPoints, () =>
          this.dashboardMng.ensureCustomPointsCache(
            dateRange,
            customPoints,
            cache,
          ),
        ),
      ),
    );
  });

  recognitionReadings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleRecognition,
        DashboardActions.toggleRecognitionVariable,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectRecognitions),
        this.store.select(
          dashboardFeature.selectCacheByType(
            DashboardCacheType.recognitionReadings,
          ),
        ),
      ]),
      exhaustMap(([, dateRange, recognitions, cache]) =>
        this.doEnsureCache(DashboardCacheType.recognitionReadings, () =>
          this.dashboardMng.ensureRecognitionReadingsCache(
            dateRange,
            recognitions,
            cache,
          ),
        ),
      ),
    );
  });

  cameraImagesDashboard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleDevice,
        DashboardActions.toggleImagesDisplay,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectImagesDisplayed),
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectDevices),
        this.store.select(
          dashboardFeature.selectCacheByType(DashboardCacheType.images),
        ),
      ]),
      filter(([, imagesDisplayed]) => imagesDisplayed),
      exhaustMap(([, , dateRange, devices, cache]) =>
        this.doEnsureCache(DashboardCacheType.images, () =>
          this.dashboardMng.ensureImagesCache(dateRange, devices, cache),
        ),
      ),
    );
  });

  cameraImagesStandalone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.refreshStandaloneCameraImages),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectDevicesForActiveLocation),
        this.store.select(
          dashboardFeature.selectCacheByType(DashboardCacheType.images),
        ),
      ]),
      exhaustMap(([, devices, cache]) =>
        this.doEnsureCache(DashboardCacheType.images, () =>
          this.dashboardMng.ensureImagesCache(
            dateRanges.find(
              (dr) => dr.name === DateRangesPredefined.last24hours,
            )!,
            devices,
            cache,
          ),
        ),
      ),
    );
  });

  cameraAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleDevice,
        DashboardActions.toggleImagesDisplay,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectImagesDisplayed),
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectRecognitions),
        this.store.select(
          dashboardFeature.selectCacheByType(
            DashboardCacheType.recognitionAreas,
          ),
        ),
      ]),
      filter(([, imagesDisplayed]) => imagesDisplayed),
      exhaustMap(([, , dateRange, recognitions, cache]) =>
        this.doEnsureCache(DashboardCacheType.recognitionAreas, () =>
          this.dashboardMng.ensureRecognitionAreasCache(
            dateRange,
            recognitions,
            cache,
          ),
        ),
      ),
    );
  });

  toggleImagesDisplay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.toggleDevice,
        DashboardActions.toggleImagesDisplay,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectImagesDisplayed),
        this.store.select(dashboardFeature.isAnyCameraSelected),
      ]),
      filter(
        ([, imagesDisplayed, camerasSelected]) =>
          imagesDisplayed && !camerasSelected,
      ),
      map(() => DashboardActions.toggleImagesDisplay()),
    );
  });

  cacheUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.cacheUpdated),
      map((action) => {
        switch (action.key) {
          case DashboardCacheType.images:
          case DashboardCacheType.recognitionAreas:
            return DashboardActions.refreshDashboardCameraImages();
          default:
            return DashboardActions.regenerateChart();
        }
      }),
    );
  });

  imagesCacheUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.cacheUpdated),
      filter((action) => action.key === DashboardCacheType.images),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDashboardState),
        this.store.select(adminFeature.selectDevicesForActiveLocation),
      ]),
      switchMap(([, dashboardState, devices]) =>
        from(
          this.dashboardMng.getStandaloneCameraImagesToDisplay(
            dashboardState.cache,
            devices,
          ),
        ).pipe(
          map((images) =>
            DashboardActions.refreshStandaloneCameraImagesSuccess({ images }),
          ),
        ),
      ),
    );
  });

  regenerateChart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.regenerateChart,
        DashboardActions.toggleDevice,
        DashboardActions.toggleVariable,
        DashboardActions.focusDevice,
        DashboardActions.chartUomConfigChanged,
        DashboardActions.chartCommentsFetched,
        DashboardActions.chartCommentSaveSucceeded,
        DashboardActions.chartCommentDeleted,
        NodesActions.nodeUpdated,
        AdminActions.variableConfigUpdated,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDashboardState),
        this.store.select(adminFeature.selectVariables),
      ]),
      map(([, dashboardState, hostVariables]) =>
        DashboardActions.regenerateChartSuccess({
          result: this.dashboardMng.generateChart(
            dashboardState,
            hostVariables,
          ),
        }),
      ),
    );
  });

  userSettingsChangeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.userSettingsChangeSuccess),
      delay(1000),
      map(() => DashboardActions.refresh()),
    );
  });

  refresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.refresh),
      concatLatestFrom(() =>
        this.store.select(dashboardFeature.selectDateRange),
      ),
      map(([, dateRange]) => DashboardActions.dateRangeChanged({ dateRange })),
    );
  });

  refreshCameraImages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.refreshDashboardCameraImages,
        DashboardActions.toggleDevice,
        DashboardActions.chartHover,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDashboardState),
      ]),
      switchMap(([, dashboardState]) =>
        from(
          this.dashboardMng.getDashboardCameraImagesToDisplay(dashboardState),
        ).pipe(
          map((images) =>
            DashboardActions.refreshDashboardCameraImagesSuccess({ images }),
          ),
        ),
      ),
    );
  });

  // TODO: can lead to multiple chart redraw and even extra API calls, refactor this
  catchUpDateRangeChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.applySavedConfig,
        DashboardActions.focusChartComment,
      ),
      map(() => DashboardActions.reapplyCurrentDateRange()),
    );
  });

  reapplyCurrentDateRange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.reapplyCurrentDateRange),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDateRange),
      ]),
      map(([, dateRange]) => DashboardActions.dateRangeChanged({ dateRange })),
    );
  });

  restoreSavedConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.navigateToApp),
      filter((action) => !action.ignoreSideEffects),
      map(() => localStorage.getItem(this.savedConfigsPersistentKey)),
      filter((savedConfigsRaw) => !!savedConfigsRaw),
      map((savedConfigsRaw) =>
        DashboardActions.restoreSavedConfigs({
          savedConfigs: JSON.parse(savedConfigsRaw ?? ''),
        }),
      ),
    );
  });

  persistSavedConfigs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          DashboardActions.saveConfig,
          DashboardActions.updateConfig,
          DashboardActions.deleteSavedConfig,
          DashboardActions.setDefaultConfig,
          DashboardActions.unsetDefaultConfig,
        ),
        concatLatestFrom(() => [
          this.store.select(dashboardFeature.selectSavedConfigs),
        ]),
        tap(([, savedConfigs]) =>
          localStorage.setItem(
            this.savedConfigsPersistentKey,
            JSON.stringify(savedConfigs),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  // TODO: fix double readings loadings in some cases
  applyDefaultConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationConfigRetrived),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
        this.store.select(dashboardFeature.selectSavedConfigs),
      ]),
      map(([, activeLocation, savedConfigs]) =>
        savedConfigs.find(
          (c) =>
            c.locationId === activeLocation!.location!.central_id && c.default,
        ),
      ),
      filter((defaultConfig) => !!defaultConfig),
      map((defaultConfig) =>
        DashboardActions.applySavedConfig({
          savedConfig: defaultConfig!,
        }),
      ),
    );
  });

  chartTooltip$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.chartHover),
        filter((action) => !!action.timestamp),
        concatLatestFrom(() => [
          this.store.select(dashboardFeature.selectChartData),
        ]),
        tap(([action, chartData]) =>
          this.dashboardMng.generateTooltips(
            action.timestamp!,
            action.clientX,
            action.clientY,
            chartData.data.datasets,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  automaticallySelectCameraRecognition$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.toggleDevice),
        concatLatestFrom((action) => [
          this.store.select(dashboardFeature.selectDashboardState),
          this.store.select(
            adminFeature.selectDeviceSettings(action.device.thermal_camera_id),
          ),
        ]),
        filter(
          ([action, state, settings]) =>
            state.devices.some(
              (d) => d.thermal_camera_id === action.device.thermal_camera_id,
            ) && !!settings?.flower_recognition,
        ),
        tap(([action, state]) =>
          this.dashboardMng.ensureCameraRecognitionsPreselected(
            action.device,
            state,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  shiftDateRange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.shiftDateRange),
      concatLatestFrom(() =>
        this.store.select(dashboardFeature.selectDateRange),
      ),
      map(([action, dateRange]) => {
        const currentStart = dateRange.start();
        const currentEnd = dateRange.end();
        const deltaMS =
          action.delta *
          Math.round(currentEnd.diff(currentStart, 'days', true));

        const newStart = currentStart.add(deltaMS, 'days');
        const newEnd = currentEnd.add(deltaMS, 'days');

        const now = dayjs();
        return newStart.isBefore(now) && newEnd.isBefore(now)
          ? { start: newStart, end: newEnd }
          : undefined;
      }),
      filter((newRange) => !!newRange),
      map((newRange) =>
        DashboardActions.dateRangeChanged({
          dateRange: DateRange.fromStartEnd(newRange!.start, newRange!.end),
        }),
      ),
    );
  });

  autoRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.navigateToApp),
      switchMap(() =>
        interval(60000).pipe(map(() => DashboardActions.refresh())),
      ),
    );
  });

  chartClick$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.chartClick),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectChartData),
      ]),
      switchMap(([action, chartData]) =>
        from(
          this.dashboardMng.onChartClick(
            chartData.data.datasets,
            action.chartX,
          ),
        ).pipe(
          filter((commentInput) => !!commentInput),
          map((commentInput) =>
            DashboardActions.chartCommentAdded({
              comment: {
                chartX: action.chartX,
                chartY: action.chartY,
                text: commentInput!.text,
                color: commentInput!.color,
              },
            }),
          ),
        ),
      ),
    );
  });

  loadChartComments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationConfigRetrived),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      filter((values) => values.every((v) => !!v)),
      switchMap(([, activeLocation]) =>
        this.dataApi
          .chartsCommentRetrieve(activeLocation!.location!.central_id)
          .pipe(
            map((res) =>
              DashboardActions.chartCommentsFetched({
                comments: res.charts_comments
                  .filter((c) => !!c.unix_timestamp)
                  .sort((c1, c2) => c2.unix_timestamp - c1.unix_timestamp),
              }),
            ),
          ),
      ),
    );
  });

  saveChartComments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.chartCommentAdded),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
        this.store.select(dashboardFeature.selectDashboardState),
      ]),
      filter((values) => values.every((v) => !!v)),
      switchMap(([action, activeLocation, state]) =>
        this.dataApi
          .chartsCommentCreate(
            activeLocation!.location!.central_id,
            new ChartsCommentPostRequest({
              comment: action.comment.text,
              color: action.comment.color,
              unix_timestamp: Math.floor(action.comment.chartX / 1000),
              reading: action.comment.chartY,
              configs: {
                dashboardConfig:
                  DashboardUtils.getDashboardConfigFromState(state),
                startTimestamp: state.dateRange.start().valueOf(),
                endTimestamp: state.dateRange.end().valueOf(),
              } satisfies IChartCommentConfig,
            }),
          )
          .pipe(
            map((res) =>
              DashboardActions.chartCommentSaveSucceeded({
                comment: res.charts_comment,
              }),
            ),
          ),
      ),
    );
  });

  deleteChartComments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.chartCommentDeleted),
        concatLatestFrom(() => [
          this.store.select(adminFeature.selectActiveLocation),
        ]),
        filter((values) => values.every((v) => !!v)),
        switchMap(([action, activeLocation]) =>
          this.dataApi.chartsCommentDestroy(
            activeLocation!.location!.central_id,
            action.id,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardMng: DashboardService,
    private dataApi: DataApi,
  ) {}

  private doEnsureCache(
    type: DashboardCacheType,
    ensureFunction: () => Promise<unknown>,
  ) {
    return from(ensureFunction()).pipe(
      map((cache) =>
        DashboardActions.cacheUpdated({
          key: type,
          value: cache,
        }),
      ),
    );
  }
}
