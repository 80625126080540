import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, finalize, throwError } from 'rxjs';
import { API_BASE_URL } from '../api/api-sdk';
import { UiService } from '../services/ui.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private ui: UiService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!req.url.startsWith(this.apiBaseUrl)) {
      return next.handle(req);
    }

    this.ui.toggleLoading(true);

    return next.handle(req).pipe(
      catchError((error) => {
        this.ui.showErrorMessage();
        return throwError(() => new Error(error));
      }),
      finalize(() => this.ui.toggleLoading(false)),
    );
  }
}
