import { Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../../../../../common/base.component';
import { AlphaPipe } from '../../../../../common/pipes/alpha.pipe';
import { DpDateFormatPipe } from '../../../../../common/pipes/dayjs/dp-date-format.pipe';
import { DpFromUnixMSPipe } from '../../../../../common/pipes/dayjs/dp-from-unix-ms.pipe';
import { ITooltip } from '../../../../../model/dashboard';

export interface ICommentInput {
  text: string;
  color: string;
}

@Component({
  selector: 'app-chart-point-details',
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    DpFromUnixMSPipe,
    DpDateFormatPipe,
    AlphaPipe,
  ],
  templateUrl: './chart-point-details.component.html',
  styleUrl: './chart-point-details.component.scss',
})
export class ChartPointDetailsComponent extends BaseComponent {
  commentCtrl = new FormControl('', Validators.required);
  colors = ['#0091db', '#eaf2f2', '#2ba581', '#C23B85', '#285172'];
  selectedColor = this.colors[0];

  constructor(
    @Inject(MAT_DIALOG_DATA) public tooltips: ITooltip[],
    private dialogRef: MatDialogRef<ChartPointDetailsComponent>,
  ) {
    super();
  }

  selectColor(color: string) {
    this.selectedColor = color;
  }

  confirm() {
    this.dialogRef.close({
      text: this.commentCtrl.value!,
      color: this.selectedColor,
    } satisfies ICommentInput);
  }
}
