import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import _, { isNumber } from 'lodash';
import { lastValueFrom, take } from 'rxjs';
import { IVariableToDisplay } from '../../../../api/api-sdk';
import { BaseDashboardService } from '../../../../common/base.dashboard.service';
import { AlertType } from '../../../../model/alert';
import { IConfiguredDevice } from '../../../../model/dashboard';
import { INodeListItem, IVariableAlert } from '../../../../model/node';
import { INodesState } from '../state/nodes.feature';

@Injectable({
  providedIn: 'root',
})
export class NodesService extends BaseDashboardService {
  constructor(private translate: TranslateService) {
    super();
  }

  getNodesAndAlerts(state: INodesState, devices: IConfiguredDevice[]) {
    const nodes: INodeListItem[] = [];
    const attrAlerts = new Map<string, Set<AlertType>>();

    for (const node of state.nodesData) {
      const device = devices.find((d) => d.remote_id === node.remote_id);
      const item: INodeListItem = {
        id: node.remote_id,
        name: node.node_name,
        date: dayjs.unix(node.timestamp_unix),
        cameraId: device?.thermal_camera_id,
        lastReading: device ? dayjs.unix(device.timestamp_unix) : undefined,
        attributes: [],
        alerts: [],
        visible: node.visible,
      };

      const attrs = _(state.attrGroups)
        .map((g) => g.attributes)
        .flatten()
        .value();

      for (const attr of attrs) {
        const value = node[attr.valuePath];
        const itemAttribute = {
          ...attr,
          value,
          alertType: this.getAlertType(
            value,
            attr?.alertMinValue,
            attr?.alertMaxValue,
          ),
        };

        if (itemAttribute.alertType !== AlertType.unknown) {
          if (!item.alerts.includes(itemAttribute.alertType)) {
            item.alerts.push(itemAttribute.alertType);
          }
          const attrAlertTypes =
            attrAlerts.get(attr.id) ?? new Set<AlertType>();
          attrAlertTypes.add(itemAttribute.alertType);
          attrAlerts.set(attr.id, attrAlertTypes);
        }

        item.attributes.push(itemAttribute);
      }

      nodes.push(item);
    }
    return {
      nodes,
      alerts: Array.from(attrAlerts).map(
        ([id, alertTypes]) =>
          ({
            id,
            alertTypes: Array.from(alertTypes).sort(),
          }) satisfies IVariableAlert,
      ),
    };
  }

  async getReadingColumns(variables: IVariableToDisplay[]) {
    const staticColumns: ColDef[] = [
      {
        field: 'remote_id',
        headerName: await this.getString('remoteIDdevice'),
      },
      {
        field: 'node_name',
        headerName: await this.getString('nodeName'),
      },
      {
        field: 'date',
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const timestamp1 = nodeA.data.timestamp_unix;
          const timestamp2 = nodeB.data.timestamp_unix;
          if (timestamp1 == timestamp2) return 0;
          return timestamp1 > timestamp2 ? 1 : -1;
        },
      },
    ];

    const variableColumns: ColDef[] = [];

    for (const variable of variables) {
      const headerName = await this.getString(variable.name);
      variableColumns.push({
        field: variable.name,
        headerName,
        cellStyle: (params: { value: number }) => {
          const alertType = this.getAlertType(
            params.value,
            variable.min,
            variable.max,
          );
          return {
            backgroundColor:
              alertType === AlertType.high
                ? '#fce7f3'
                : alertType === AlertType.low
                  ? '#e0e7ff'
                  : '#fff',
          };
        },
      });
    }

    return [...staticColumns, ...variableColumns];
  }

  private async getString(key: string) {
    return await lastValueFrom(this.translate.get(key).pipe(take(1)));
  }

  private getAlertType(value: any, alertMinValue: any, alertMaxValue: any) {
    return isNumber(value)
      ? isNumber(alertMaxValue) && value >= alertMaxValue
        ? AlertType.high
        : isNumber(alertMinValue) && value <= alertMinValue
          ? AlertType.low
          : AlertType.unknown
      : AlertType.unknown;
  }
}
